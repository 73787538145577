<script setup lang="ts">
  import {computed, onMounted, ref} from 'vue';
  import {
    arrow as arrowMiddleware,
    autoUpdate,
    flip,
    offset as offsetMiddleware,
    type Placement,
    type Strategy,
    useFloating,
  } from '@floating-ui/vue';
  import {useWarmup} from '@console/composables/useWarmup';
  import {TransitionRoot} from '@headlessui/vue';

  const props = withDefaults(
    defineProps<{
      open?: boolean;
      placement?: Placement;
      strategy?: Strategy;
      offset?: number;
      arrow?: boolean;
    }>(),
    {open: false, placement: 'right', strategy: 'fixed', offset: 8, arrow: true}
  );

  const {withWarmup, resetTimeouts} = useWarmup();

  const trigger = ref<HTMLElement | null>(null);
  const target = ref<HTMLElement | null>(null);
  const floatingArrow = ref<HTMLElement | null>(null);

  const middleware = ref([offsetMiddleware(props.offset), flip()]);

  if (props.arrow) {
    middleware.value.push(arrowMiddleware({element: floatingArrow}));
  }

  const {
    floatingStyles,
    middlewareData,
    placement: finalPlacement,
  } = useFloating(trigger, target, {
    placement: props.placement,
    strategy: props.strategy,
    middleware: middleware,
    whileElementsMounted: autoUpdate,
  });

  function showLabel() {
    withWarmup(() => {
      tooltipVisible.value = true;
    });
  }

  function hideLabel() {
    resetTimeouts();
    tooltipVisible.value = false;
  }

  const isOpen = ref(false);
  const tooltipVisible = computed({
    get() {
      return props.open || isOpen.value;
    },
    set(value) {
      isOpen.value = value;
    },
  });

  onMounted(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        tooltipVisible.value = false;
      }
    });
  });
</script>

<template>
  <div class="tooltip text-sm" @mouseover="showLabel" @mouseleave="hideLabel">
    <div class="tooltip__trigger" ref="trigger">
      <slot name="trigger"></slot>
    </div>

    <transition-root
      :show="tooltipVisible"
      class="relative z-50"
      enter="transition-opacity duration-70"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-50"
      leave-from="opacity-100"
      leave-to="opacity-0"
    >
      <div
        ref="target"
        class="tooltip__target"
        :style="floatingStyles"
        :class="{
          'py-1 px-2 bg-white dark:bg-gray-700 rounded-md border border-gray-300 dark:border-gray-700 shadow': true,
          'pointer-events-auto': tooltipVisible,
          'pointer-events-none': !tooltipVisible,
        }"
      >
        <div
          ref="floatingArrow"
          class="absolute bg-white dark:bg-gray-700 w-2 h-2 rotate-45 border-gray-300 dark:border-gray-700"
          :data-middleware="JSON.stringify(middlewareData)"
          :class="{
            'border-b border-l -translate-x-1/2 left-0':
              finalPlacement === 'right',
            'border-t border-r translate-x-1/2 right-0':
              finalPlacement === 'left',
            'border-l border-t -translate-y-1/2 top-0':
              finalPlacement === 'bottom',
            'border-r border-b translate-y-1/2 bottom-0':
              finalPlacement === 'top',
          }"
          :style="{
            left:
              middlewareData.arrow?.x != null
                ? `${middlewareData.arrow.x}px`
                : '',
            top:
              middlewareData.arrow?.y != null
                ? `${middlewareData.arrow.y}px`
                : '',
          }"
        />
        <slot />
      </div>
    </transition-root>
  </div>
</template>

<style scoped></style>
