import Added from './Added.vue'
import ArrowOut from './ArrowOut.vue'
import ArrowTurnDownLeft from './ArrowTurnDownLeft.vue'
import ArrowUpRightFromSquareRegular from './ArrowUpRightFromSquareRegular.vue'
import ArrowsRightLeft from './ArrowsRightLeft.vue'
import ArrowsRotate from './ArrowsRotate.vue'
import BookOpen from './BookOpen.vue'
import Box from './Box.vue'
import Branch from './Branch.vue'
import Buildings from './Buildings.vue'
import Changed from './Changed.vue'
import ChartSquareBar from './ChartSquareBar.vue'
import CheckCircle from './CheckCircle.vue'
import Check from './Check.vue'
import ChevronDown from './ChevronDown.vue'
import ChevronLeft from './ChevronLeft.vue'
import ChevronRight from './ChevronRight.vue'
import ChevronUp from './ChevronUp.vue'
import Cloud from './Cloud.vue'
import Cog from './Cog.vue'
import Collection from './Collection.vue'
import Copy from './Copy.vue'
import CreditCard from './CreditCard.vue'
import Deleted from './Deleted.vue'
import DotsHorizontal from './DotsHorizontal.vue'
import FileInvoiceDollar from './FileInvoiceDollar.vue'
import Gauge from './Gauge.vue'
import Identification from './Identification.vue'
import InfoCircle from './InfoCircle.vue'
import Key from './Key.vue'
import LightningBolt from './LightningBolt.vue'
import LockClosed from './LockClosed.vue'
import LockOpen from './LockOpen.vue'
import Mail from './Mail.vue'
import Menu from './Menu.vue'
import Minus from './Minus.vue'
import No from './No.vue'
import Pencil from './Pencil.vue'
import Plug from './Plug.vue'
import Plus from './Plus.vue'
import Refresh from './Refresh.vue'
import Search from './Search.vue'
import ShoppingCart from './ShoppingCart.vue'
import Shuffle from './Shuffle.vue'
import Star from './Star.vue'
import Sun from './Sun.vue'
import Support from './Support.vue'
import UserGroup from './UserGroup.vue'
import WrenchScrewdriver from './WrenchScrewdriver.vue'
import X from './X.vue'

export default {
    'added': Added,
    'arrow-out': ArrowOut,
    'arrow-turn-down-left': ArrowTurnDownLeft,
    'arrow-up-right-from-square-regular': ArrowUpRightFromSquareRegular,
    'arrows-right-left': ArrowsRightLeft,
    'arrows-rotate': ArrowsRotate,
    'book-open': BookOpen,
    'box': Box,
    'branch': Branch,
    'buildings': Buildings,
    'changed': Changed,
    'chart-square-bar': ChartSquareBar,
    'check-circle': CheckCircle,
    'check': Check,
    'chevron-down': ChevronDown,
    'chevron-left': ChevronLeft,
    'chevron-right': ChevronRight,
    'chevron-up': ChevronUp,
    'cloud': Cloud,
    'cog': Cog,
    'collection': Collection,
    'copy': Copy,
    'credit-card': CreditCard,
    'deleted': Deleted,
    'dots-horizontal': DotsHorizontal,
    'file-invoice-dollar': FileInvoiceDollar,
    'gauge': Gauge,
    'identification': Identification,
    'info-circle': InfoCircle,
    'key': Key,
    'lightning-bolt': LightningBolt,
    'lock-closed': LockClosed,
    'lock-open': LockOpen,
    'mail': Mail,
    'menu': Menu,
    'minus': Minus,
    'no': No,
    'pencil': Pencil,
    'plug': Plug,
    'plus': Plus,
    'refresh': Refresh,
    'search': Search,
    'shopping-cart': ShoppingCart,
    'shuffle': Shuffle,
    'star': Star,
    'sun': Sun,
    'support': Support,
    'user-group': UserGroup,
    'wrench-screwdriver': WrenchScrewdriver,
    'x': X,
}
