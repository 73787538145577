import {getPrefixedTo} from '@console/helpers/router';
import appApi from '@console/api/app';

const getAppRoutes = (type) => {
  return [
    {
      path: '/',
      name: type + '.index',
      beforeEnter: () => {
        const lastOrgSlug = appApi.getLastOrgSlug();
        return lastOrgSlug ? `/accounts/${lastOrgSlug}` : getPrefixedTo('/');
      },
    },
    {
      path: `/accounts/${type === 'org' ? ':orgSlug' : 'me'}`,

      children: [
        {
          path: '',
          name: type + '.context.index',
          beforeEnter: () => {
            return getPrefixedTo('/licenses');
          },
        },
        // Payment
        {
          path: 'payment',
          name: type + '.payment',
          component: () => import('@console/pages/payment/index.vue'),
          meta: {layout: 'checkout', title: 'Payment'},
        },
        {
          path: 'thank-you',
          name: type + '.thankYou',
          component: () => import('@console/pages/thank-you.vue'),
          meta: {layout: 'checkout', title: 'Thank You'},
        },

        // Approval Requested
        {
          path: 'approval-requested',
          name: type + '.approvalRequested',
          component: () => import('@console/pages/approval-requested.vue'),
          meta: {layout: 'checkout', title: 'Approval Requested'},
        },

        // Guest cart route (user only)
        {
          path: 'cart/guest',
          name: type + '.guest.cart',
          component: () => import('@console/pages/cart/guest.vue'),
        },
      ],
    },
  ];
};

const routes = [
  ...getAppRoutes('user'),
  ...getAppRoutes('org'),

  // TODO: Implement the dashboard for users and orgs
  // {
  //   path: '/dashboard',
  //   name: 'user.dashboard',
  //   component: () => import('@console/pages/index.vue'),
  //   meta: {title: 'Dashboard'},
  // },
  // {
  //   path: '/accounts/:orgSlug/dashboard',
  //   name: 'org.dashboard',
  //   component: () => import('@console/pages/index.vue'),
  //   meta: {title: 'Dashboard'},
  // },

  // Plugin and CMS buy routes (anonymous)
  {
    path: '/buy/plugin/:handle/:edition',
    name: 'buy.plugin',
    component: () => import('@console/pages/buy/_type.vue'),
    meta: {layout: 'site', allowAnonymous: true},
  },
  {
    path: '/buy/cms/:edition',
    name: 'buy.cms',
    component: () => import('@console/pages/buy/_type.vue'),
    meta: {layout: 'site', allowAnonymous: true},
  },

  // Redirect for old buy routes
  {
    path: '/buy-plugin/:handle/:edition',
    redirect: (to) => {
      return '/buy/plugin/' + to.params.handle + '/' + to.params.edition;
    },
  },
  {
    path: '/buy-cms/:edition',
    redirect: (to) => {
      return '/buy/cms/' + to.params.edition;
    },
  },

  // {
  //   path: '/rate/:pluginHandle',
  //   name: 'rate.plugin',
  //   component: () => import('@console/pages/rate/plugin.vue'),
  //   meta: {layout: 'site', title: 'Rate Plugin'},
  // },

  // Set cart (anonymous)
  {
    path: '/set-cart/:orderNumber',
    name: 'set.cart',
    component: () => import('@console/pages/set-cart.vue'),
    meta: {layout: 'site', allowAnonymous: true, title: 'Choose a cart'},
  },

  // Cart (anonymous)
  {
    path: '/cart',
    name: 'guest.cart',
    component: () => import('@console/pages/cart/index.vue'),
    meta: {allowAnonymous: true},
  },

  // Cart (user)
  {
    path: '/accounts/me/cart',
    name: 'user.cart',
    component: () => import('@console/pages/cart/index.vue'),
    meta: {allowAnonymous: true},
  },

  // Cart (org)
  {
    path: '/accounts/:orgSlug/cart',
    name: 'org.cart',
    component: () => import('@console/pages/cart/index.vue'),
  },

  // Handle old routes
  {
    path: '/orgs/:orgSlug:afterRoute(.*)',
    redirect: (to) => `/accounts/${to.params.orgSlug}${to.params.afterRoute}`,
  },
];

export default routes;
