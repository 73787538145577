const routes = [
  {
    path: '/accounts/me/plugin-store/reviews',
    name: 'user.plugin-store.reviews',
    meta: {userOnly: true, title: 'Reviews'},
    children: [
      {
        path: '',
        name: 'user.plugin-store.reviews.index',
        component: () =>
          import('@console/pages/plugin-store/reviews/index.vue'),
      },
      {
        path: ':pluginHandle',
        name: 'user.plugin-store.reviews.edit',
        component: () =>
          import('@console/pages/plugin-store/reviews/plugin.vue'),
        meta: {userOnly: true, title: 'Add Review'},
      },
    ],
  },

  {
    path: '/accounts/:orgSlug/plugin-store',
    redirect: (to) => {
      return '/accounts/' + to.params.orgSlug + '/plugin-store/sales';
    },

    children: [
      {
        path: 'plugins',
        meta: {expandedSidebar: true},

        children: [
          {
            path: '',
            name: 'org.plugin-store.plugins',
            component: () =>
              import('@console/pages/plugin-store/plugins/index.vue'),
            meta: {orgOnly: true, title: 'Plugins'},
          },
          {
            path: ':id',
            name: 'org.plugin-store.plugins.id',
            component: () =>
              import('@console/pages/plugin-store/plugins/_id.vue'),
          },
          {
            path: ':id/reviews',
            name: 'plugin-store.plugin.reviews',
            component: () =>
              import('@console/pages/plugin-store/plugins/reviews.vue'),
          },
          {
            path: '/accounts/:orgSlug/plugin-store/add-plugin',
            name: 'org.plugin-store.plugins.new',
            component: () =>
              import('@console/pages/plugin-store/plugins/_id.vue'),
          },
        ],
      },
      {
        path: 'sales',
        name: 'org.plugin-store.sales',
        component: () => import('@console/pages/plugin-store/sales/index.vue'),
        meta: {orgOnly: true, title: 'Sales', expandedSidebar: true},
      },
    ],
  },

  // Handle old routes
  {
    path: '/accounts/:orgSlug/developer:afterRoute(.*)',
    redirect: (to) =>
      `/accounts/${to.params.orgSlug}/plugin-store${to.params.afterRoute}`,
  },
];

export default routes;
